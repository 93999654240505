import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingIso4500118001Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'ISO 45001'

  const description =
    'ISO 45001 è applicabile a tutte le organizzazioni che vogliano formalizzare un sistema di gestione che abbia la finalità di eliminare o ridurre i rischi a cui i lavoratori sono esposti nello svolgimento delle proprie attività allo scopo di fornire alle aziende dei criteri di gestione dei rischi relativi a salute e sicurezza dei dipendenti.<br> Il rispetto della norma non rende immuni dal rispetto degli obblighi di legge.<br><br> <b>LA NORMA ISO 45001 – DETTAGLI</b><br><br> L’introduzione di un sistema di gestione per la salute e la sicurezza sul lavoro permette alle organizzazioni di gestire i rischi e migliorare l’efficienza negli ambiti della salute e della sicurezza in materia di prevenzione di malattie e incidenti.<br> La norma ISO ha la cosiddetta “Struttura di Alto Livello” per motivi di standardizzazione così come per le nuove versioni della ISO 9001:2015 e della ISO 14001:2015, ovvero:<br><br> <ul><li> Identica struttura per tutti i sistemi di gestione;</li> <li>Stesso utilizzo di testi e terminologia;</li> <li>Standard più facili da capire;</li> <li>Applicazione più efficiente di sistemi di gestione integrati.</li></ul> <br><b>ING GROUP ha realizzato numerosi sistemi di gestione conformi alla ISO 45001, assicurando ai propri clienti:</b> <br><br> <ul><li>Analisi iniziale</li> <li>Individuazione dei processi aziendali, interazioni e misure</li> <li>Redazione delle procedure e dei vari moduli richiesti</li> <li>Sensibilizzazione e ausilio nell’applicazione del sistema documentato</li> <li>Conduzione delle Viste Ispettive Interne</li> <li>Gestione del rapporto con gli Enti di certificazione</li> <li>Correzione delle eventuali non conformità rilevate dall’Ente di certificazione</li></ul>'
  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
